/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
img {
  max-width: 100%;
  object-fit: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 20px;
}

.chat-log {
  max-height: 300px;
  overflow-y: scroll;
}

.message {
  background-color: #e9e9e9;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}

.user-message {
  background-color: #dcf8c6;
}

.bot-message {
  background-color: #c8e8ff;
}

.user-message p,
.bot-message p {
  margin: 0;
}

.user-input {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.user-input input {
  flex: 1;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 10px;
}

.user-input button {
  padding: 10px 20px;
  background-color: #4caf50;
  border: none;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.user-input button:hover {
  background-color: #45a049;
}

.user-input button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.user-input button:disabled:hover {
  background-color: #ccc;
}
